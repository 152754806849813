// Libraries

import React from "react"
import { get, findIndex, includes } from "lodash"

/*-----------------------------
Styles
-----------------------------*/

/*-----------------------------
Component
-----------------------------*/

class InvestmentsPage extends React.Component {
  constructor(props) {
    super(props)

    // State
    this.state = {
      currentActive: 0,
      currentActiveInvestmentDetail: {},
      activeInvestment: {
        category: "",
        investmentSubtitle: "",
        readMoreContent: "",
        investmentImages: [],
        nextSlideDetail: {
          category: "",
          title: "",
        },
      },
      readMoreFlagStatus: false,
      subTitle: "",
      investmentList: [],
      noPost: false,
    }
  }

  componentDidMount() {
    //Assigning the props value to a variable. So that we avoid writing repeating node the get the adequate props value.
    const allInvestmentPages = this.props.data.allWordpressWpInvestment.nodes

    //set current investment based on query slug
    if (Array.isArray(allInvestmentPages) && allInvestmentPages.length) {
      this.setInvestmentBySlug()
    } else {
      this.setState({ noPost: true })
    }
  }

  // set investment by slug on mount
  setInvestmentBySlug = () => {
    const allInvestmentPages = this.props.data.allWordpressWpInvestment.nodes
    if (!(Array.isArray(allInvestmentPages) && allInvestmentPages.length))
      return
    // set readmore flag active if page route is hit from home page readmore
    let searchParams = window && window.location.href.split("&")
    let searchSlug =
      get(searchParams, "[0]") && get(searchParams, "[0]").replace(/^\?/, "")
    let searchSlugParam = searchSlug && searchSlug.split("=")
    let currentActive = 0
    if (searchSlugParam[1] || this.props["*"]) {
      let routeActiveInvestmentIndex = findIndex(allInvestmentPages, elem => {
        return includes(
          [this.props["*"], searchSlugParam[1]],
          get(elem, "custom.slug")
        )
      })

      currentActive =
        routeActiveInvestmentIndex > -1 ? routeActiveInvestmentIndex : 0
    } else {
      // set slug for first investment
      let slug = get(allInvestmentPages[currentActive], "custom.slug")
      window.history.replaceState(null, null, `${this.props.path}?slug=${slug}`)
    }

    let postType = get(
      allInvestmentPages[currentActive],
      "custom.current_investment_post"
    )

    // set index and detail for active investment
    this.setState({
      // subTitle: subTitle,
      subTitle:
        postType === "yes"
          ? "Current<br />Investments"
          : "Previous<br />Investments",
      investmentList: allInvestmentPages || [],
      currentActive,
      currentActiveInvestmentDetail: allInvestmentPages[currentActive],
    })
  }

  // Next Slide
  nextSlide = bottomNav => {
    let { currentActive } = this.state
    let lastInvestmentFlag =
      currentActive === this.state.investmentList.length - 1

    if (lastInvestmentFlag) {
      currentActive = -1
    }

    // set detail for next slide
    const currentActiveInvestmentDetail = this.state.investmentList[
      currentActive + 1
    ]

    this.setState({
      currentActive: currentActive + 1,
      currentActiveInvestmentDetail,
    })

    window.history.pushState(
      null,
      null,
      `${this.props.path}?slug=${get(
        currentActiveInvestmentDetail,
        "custom.slug"
      )}`
    )
  }

  // Previous Slide

  previousSlide = () => {
    let currentActiveInvestmentDetail
    let currentActiveNumber
    if (this.state.currentActive === 0) {
      currentActiveInvestmentDetail = this.state.investmentList[
        this.state.investmentList.length - 1
      ]

      currentActiveNumber = this.state.investmentList.length - 1
    } else {
      currentActiveInvestmentDetail = this.state.investmentList[
        this.state.currentActive - 1
      ]

      currentActiveNumber = this.state.currentActive - 1
    }

    this.setState({
      currentActive: currentActiveNumber,
      currentActiveInvestmentDetail,
    })

    window.history.pushState(
      null,
      null,
      `${this.props.path}?slug=${get(
        currentActiveInvestmentDetail,
        "custom.slug"
      )}`
    )
  }

  // check if read more flag is active in responsive
  checkIfSlideChangeInResponsive = readMoreFlagStatus => {
    this.setState({ readMoreFlagStatus })
  }

  componentDidUpdate(prevProps, prevState) {
    let prevSlug = get(prevState.currentActiveInvestmentDetail, "custom.slug")
    let url = window.location.href.split("="),
      slug = url[1],
      exactSlug = slug && slug.split("&")[0]
    if (this.state.currentActive && exactSlug && prevSlug !== exactSlug) {
      this.setInvestmentBySlug()
    }
  }

  //map and return active investment with data
  setCurrentActiveInvestment = () => {
    let { currentActive } = this.state
    const currentInvestment = this.state.investmentList[currentActive]
    let nextSlideDetail = {}

    //set next story detail if last story is reached
    let nextInvestmentIndex =
      currentActive === this.state.investmentList.length - 1
        ? 0
        : currentActive + 1

    // set next slide detail
    let nextInvestment = this.state.investmentList[nextInvestmentIndex] || {}

    nextSlideDetail = {
      category: get(nextInvestment, "categories[0].name") || "",
      title: get(nextInvestment, "custom.title") || "",
    }

    let investmentImagesList =
      get(currentInvestment, "custom.investment_image_post") || []

    let investmentImages =
      Array.isArray(investmentImagesList) &&
      investmentImagesList.map(elem => {
        return { image: get(elem, "investment_post_images") || {} }
      })

    return (
      (currentInvestment && {
        category: get(currentInvestment, "categories[0].name"),
        investmentSubtitle: get(currentInvestment, "custom.title"),
        description: get(currentInvestment, "content"),
        investmentImages,
        nextSlideDetail,
      }) ||
      {}
    )
  }
}

export default InvestmentsPage
