import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"
import { TweenMax, Power3 } from "gsap/TweenMax"
import { TweenLite } from "gsap/TweenLite"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { get, delay } from "lodash"
import ScrollToPlugin from "gsap/ScrollToPlugin"

//helpers

import { desktopSize, mobileSize } from "../../helpers/sizeCalculations"
import SlideNav from "./ArrowNav"
import Image from "../sharedPageLayout/Image"
import SwipeSlider from "../sharedPageLayout/Slider"

const plugins = [ScrollToPlugin] // eslint-disable-line no-unused-vars

/**
 * Styles Constants
 */
const InvestmentWrapper = styled.div`
  text-align: center;
  font-family: "gt-walsheim-regular";
  .read-more {
    margin-bottom: ${desktopSize(90)};
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
    text-decoration: underline;
    @media screen and (max-width: 768px) {
      margin-top: ${mobileSize(15)};
      margin-bottom: ${mobileSize(70)};
      font-size: ${mobileSize(19)};
    }
  }
`

const InvestmentCategory = styled.h5`
  letter-spacing: 0.59px;
`
const SubTitle = styled.h2`
  padding: 0 30px;
  letter-spacing: 0.59px;
  margin-top: 10px;
`

const InvestmentHeadingContainer = styled.h3`
  margin: 0;
  transform: translateY(-5px);
  padding: ${desktopSize(120)} 0 ${desktopSize(90)};
  @media all and (max-width: 768px) {
    padding: ${mobileSize(70)} 0 ${mobileSize(100)};
  }
`

const SlideContentContainer = styled.div`
  position: relative;
  min-height: ${desktopSize(340)};
  transition: height 0.5s ease-in-out;
  @media all and (max-width: 768px) {
    min-height: ${mobileSize(295)};
  }
`

const SlideContentWrapper = styled.div``

const SlideContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  &.cross-fade-slide-enter {
    opacity: 0;
  }
  &.cross-fade-slide-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  &.cross-fade-slide-exit {
    opacity: 1;
  }
  &.cross-fade-slide-exit-active {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
`

const ParaDescription = styled.div`
  padding: 0 25px 15px 0;
  margin-bottom: ${props => (props.lastSlide ? "25px" : "60px")};
  text-align: left;
  position: relative;
  &::after {
    content: "";
    width: calc(100% - 25px);
    padding-top: ${props => (props.lastSlide ? "25px" : "60px")};
    border-bottom: 2px solid #fff;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &.cross-fade-slide-enter {
    opacity: 0;
  }
  &.cross-fade-slide-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  &.cross-fade-slide-exit {
    opacity: 1;
  }
  &.cross-fade-slide-exit-active {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
`

const Description = styled.div`
  display: none;
`

// Next slide detail
const NextSlideWrapper = styled.section`
  margin-bottom: 30px;
  padding: 0 10px 10px;
  h2 {
    margin: 0;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 0;
    padding: 0 10px 25px;
  }
`

const ArrowNavContainer = styled.div`
  padding-bottom: ${desktopSize(44)};
`

const ReadMoreLink = styled.button`
  background: transparent;
  border: none;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  visibility: ${props => (props.active ? "visible" : "hidden")};
  -webkit-tap-highlight-color: transparent;
  &:hover {
    text-decoration: underline;
  }
`

const NextSlideCategory = styled.h5``
const NextSlideTitle = styled.h2``

const BottomNav = styled.div``

const InvestmentDetailContainer = styled.div`
  padding-top: 10px;
`

//Structure
class InvestmentLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      readMoreFlag: false,
      sliderActive: true,
    }

    this.heading = React.createRef()
    this.readMoreRef = React.createRef()
    this.topNavRef = React.createRef()
    this.descriptionRef = React.createRef()
    this.slideContentRef = React.createRef()
    this.SlideContentContainerRef = React.createRef()
    this.SlideContentWrapperRef = React.createRef()
  }

  componentDidMount() {
    // set readmore flag active if page route is hit from home page readmore
    let searchParams = this.props.location.search.split("&")
    let searchSlug =
      get(searchParams, "[1]") && get(searchParams, "[1]").replace(/^\?/, "")
    let searchSlugParam = searchSlug && searchSlug.split("=")

    get(searchSlugParam, "[0]") === "readMore" &&
      get(searchSlugParam, "[1]") === "true" &&
      delay(this.activateReadMore, 500, false)
  }

  activateReadMore = fadeInDescription => {
    let animateContainerCSS, animateDescriptionCSS

    //fadeOut Heading, Readmore and TopNav
    if (fadeInDescription) {
      animateContainerCSS = [
        { opacity: 0, visibility: "hidden" },
        { opacity: 1, visibility: "visible", ease: Power3.easeInOut },
      ]

      animateDescriptionCSS = [
        { opacity: 1, display: "block" },
        { opacity: 0, display: "none", ease: Power3.easeOut },
      ]
    } else {
      animateContainerCSS = [
        { opacity: 1, visibility: "visible" },
        { opacity: 0, visibility: "hidden", ease: Power3.easeOut },
      ]

      animateDescriptionCSS = [
        { opacity: 0, display: "none" },
        { opacity: 1, display: "block", ease: Power3.easeIn },
      ]
    }

    if (this.heading.current) {
      TweenMax.fromTo(this.heading.current, 0.9, ...animateContainerCSS)
    }

    if (this.readMoreRef.current) {
      TweenMax.fromTo(this.readMoreRef.current, 0.9, ...animateContainerCSS)
    }

    if (this.topNavRef.current) {
      TweenMax.fromTo(this.topNavRef.current, 0.9, ...animateContainerCSS)
    }

    if (this.descriptionRef.current) {
      TweenMax.fromTo(
        this.descriptionRef.current,
        0.9,
        ...animateDescriptionCSS
      )
    }

    // scroll to description on readmore active
    !this.state.readMoreFlag && this.scrollToInvestmentDescription()

    this.setState(
      {
        readMoreFlag: true,
        sliderActive: true,
      },
      this.props.checkIfSlideChangeInResponsive(!!!fadeInDescription)
    )
  }

  scrollToInvestmentDescription = () => {
    // scroll to description when read more is active
    let scrollableContainer = document.querySelector(".content-container")
    let subTitle = document.querySelector(".sub-title")
    scrollableContainer &&
      subTitle &&
      TweenLite.to(scrollableContainer, 0.9, {
        scrollTo: subTitle,
        delay: 0.9,
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentActive !== prevProps.currentActive) {
      this.setState({
        sliderActive: false,
      })
      if (
        this.SlideContentContainerRef &&
        this.SlideContentWrapperRef &&
        this.SlideContentContainerRef.current &&
        this.SlideContentWrapperRef.current
      ) {
        this.SlideContentContainerRef.current.style.height =
          this.SlideContentWrapperRef.current.clientHeight + "px"
      }

      if (this.state.readMoreFlag) {
        // scroll to description on readmore active
        delay(() => this.activateReadMore(), 500)
        this.scrollToInvestmentDescription()
      }
    }
  }

  render() {
    const {
      category,
      investmentSubtitle,
      description,
      previousSlide,
      nextSlide,
      currentActive,
      investmentList,
      investmentHeading,
      nextSlideDetail,
      investmentImages,
    } = this.props

    let RESPONSIVE_IMAGE_LIST = []
    if (Array.isArray(investmentImages)) {
      const IMAGE_LIST = investmentImages || []
      RESPONSIVE_IMAGE_LIST = IMAGE_LIST.map((elem, key) => {
        let fluid =
          elem.image.localFile && elem.image.localFile.childImageSharp.fluid
        return fluid && <Image className="slide" key={key} filename={fluid} />
      })
    }

    const SLIDE_CONTENT = (
      <SlideContent>
        <SlideContentWrapper ref={this.SlideContentWrapperRef}>
          {category && <InvestmentCategory>{category}</InvestmentCategory>}
          {investmentSubtitle && (
            <SubTitle className="sub-title">
              {ReactHtmlParser(investmentSubtitle)}
            </SubTitle>
          )}
          {description && (
            <ReadMoreLink
              ref={this.readMoreRef}
              className="read-more"
              onClick={() => this.activateReadMore(false)}
              active={!!description && !this.state.readMoreFlag}
            >
              Read More
            </ReadMoreLink>
          )}
        </SlideContentWrapper>
      </SlideContent>
    )

    return (
      <InvestmentWrapper>
        {investmentHeading && (
          <InvestmentHeadingContainer ref={this.heading}>
            {ReactHtmlParser(investmentHeading)}
          </InvestmentHeadingContainer>
        )}
        <SlideContentContainer ref={this.SlideContentContainerRef}>
          <TransitionGroup>
            <CSSTransition
              key={currentActive}
              timeout={{ enter: 1000, exit: 100 }}
              classNames={"cross-fade-slide"}
            >
              {SLIDE_CONTENT}
            </CSSTransition>
          </TransitionGroup>
        </SlideContentContainer>
        <ArrowNavContainer ref={this.topNavRef}>
          <SlideNav
            previousSlide={previousSlide}
            nextSlide={nextSlide}
            investmentList={investmentList}
            currentActive={currentActive}
            bottomNav={false}
          />
        </ArrowNavContainer>
        <InvestmentDetailContainer>
          {RESPONSIVE_IMAGE_LIST.length &&
            this.state.readMoreFlag &&
            this.state.sliderActive && (
              <SwipeSlider currentActive={currentActive}>
                {RESPONSIVE_IMAGE_LIST}
              </SwipeSlider>
            )}

          <Description
            readMoreFlag={this.state.readMoreFlag}
            ref={this.descriptionRef}
          >
            <TransitionGroup>
              <CSSTransition
                key={currentActive}
                timeout={1000}
                classNames={"cross-fade-slide"}
              >
                <div className={"cross-fade-slide"}>
                  <ParaDescription
                    lastSlide={currentActive === investmentList.length - 1}
                    className="ipad"
                  >
                    {ReactHtmlParser(description)}
                  </ParaDescription>

                  {nextSlideDetail && (
                    <NextSlideWrapper>
                      {nextSlideDetail.category && (
                        <NextSlideCategory>
                          {nextSlideDetail.category}
                        </NextSlideCategory>
                      )}
                      {nextSlideDetail.title && (
                        <NextSlideTitle>
                          {ReactHtmlParser(nextSlideDetail.title)}
                        </NextSlideTitle>
                      )}
                      <BottomNav>
                        <SlideNav
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                          investmentList={investmentList}
                          currentActive={currentActive}
                          bottomNav={true}
                        />
                      </BottomNav>
                    </NextSlideWrapper>
                  )}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Description>
        </InvestmentDetailContainer>
      </InvestmentWrapper>
    )
  }
}

//Validation of props.
InvestmentLayout.propTypes = {
  filename: PropTypes.object,
}

export default InvestmentLayout
