// Libraries

import React from "react"
import styled from "styled-components"

//helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

//images

import ArrowLeft from "../../icons/arrow-left.svg"
import ArrowRight from "../../icons/arrow-right.svg"

// Arrow Nav

const ArrowNav = styled.div`
  display: flex;
  justify-content: center;
`

const ArrowContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${desktopSize(68)};
  height: ${desktopSize(68)};
  border-radius: 50%;
  background: transparent;
  border: 2px solid
    ${props => (props.bottomNav ? "transparent" : props.theme.color.pink)};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  div {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    line-height: 0;
  }
  svg {
    width: ${desktopSize(40)};
    @media all and (max-width: 768px) {
      width: ${mobileSize(40)};
    }
  }
  @media all and (max-width: 768px) {
    width: ${mobileSize(68)};
    height: ${mobileSize(68)};
  }
`

const ArrowContainerRight = styled(ArrowContainer)`
  svg {
    transform: translateY(1px);
  }
  opacity: 1;
`

const ArrowContainerLeft = styled(ArrowContainer)`
  margin-right: ${props =>
    props.bottomNav ? desktopSize(10) : desktopSize(20)};
  opacity: 1;
  @media all and (max-width: 768px) {
    margin-right: ${props =>
      props.bottomNav ? mobileSize(10) : mobileSize(20)};
  }
`

const ManageSlideArrowNav = ({
  previousSlide,
  nextSlide,
  currentActive,
  bottomNav,
}) => {
  return (
    <ArrowNav>
      {bottomNav ? null : (
        <ArrowContainerLeft
          onClick={previousSlide}
          isActive={currentActive !== 0}
          bottomNav={bottomNav}
        >
          <ArrowLeft />
        </ArrowContainerLeft>
      )}
      {
        <ArrowContainerRight
          bottomNav={bottomNav}
          onClick={() => nextSlide(bottomNav)}
        >
          <ArrowRight />
        </ArrowContainerRight>
      }
    </ArrowNav>
  )
}

ManageSlideArrowNav.defaultProps = {
  previousSlide: null,
  nextSlide: null,
  investmentList: [],
  currentActive: false,
  bottomNav: false,
}

export default ManageSlideArrowNav
